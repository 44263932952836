<template>
	<nav v-click-outside="hideMenu" class="main" :class="{ active: showMenu }">
		<div class="row">
			<div class="columns column12">
				<nuxt-link
					class="logo"
					:class="page.headingImage ? '' : 'black'"
					aria-label="Back to homepage"
					:to="localePath('/')"
				>
					<strong>Berlin</strong><br />
					<span>Hotel Template</span>
				</nuxt-link>
				<div class="nav-container">
					<ul class="main-nav">
						<li
							v-for="item in topmenu[locale]"
							:key="item.filename"
							:class="{ 'has-dropdown': item.submenu.length }"
						>
							<nuxt-link :exact="item.slug === 'index'" :to="`${item.filename}`" @click="hideMenu">
								{{ item.header }}
							</nuxt-link>
							<submenu
								v-if="item.submenu.length"
								:hide-all="!showMenu"
								:item="item"
								:subitems="item.submenu"
								@hide-menu="hideMenu"
							/>
						</li>
					</ul>
					<div class="contact-info">
						<p>
							<strong>{{ defaults[locale].website.hotelName }}</strong>
							<br />
							<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span>
							<br />
							<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span>
							<br />
							<span>{{ defaults[locale].website.country }}</span>
							<br />
							<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
								<span>{{ defaults[locale].website.phone }}</span>
							</a>
							<br />
							<a :href="`mailto:${defaults[locale].website.email}`">
								<span>{{ defaults[locale].website.email }}</span>
							</a>
						</p>
						<social-media :socials="socials" />
					</div>
					<ul v-if="page" class="lang-nav">
						<li v-for="item in page.langmenu" :key="`${item.filename}-${item.language}`">
							<nuxt-link class="flag" :class="item.language" :to="`${item.filename}`" @click="hideMenu">
								{{ $t(item.language.toLowerCase()) }}
							</nuxt-link>
						</li>
					</ul>
				</div>
				<div class="buttons">
					<book-button class="button button-cta reserve-now right">
						<font-awesome-icon icon="fa-light fa-calendar-days" size="1x" />
						<span>{{ $t('bookNow') }}</span>
					</book-button>
					<div class="menu-button button right button-en" :class="{ active: showMenu }" @click="toggleMenu">
						<font-awesome-icon v-show="showMenu" icon="fa-light fa-times" size="1x" />
						<font-awesome-icon v-show="!showMenu" icon="fa-light fa-bars" size="1x" />
						<span>{{ $t('menu') }}</span>
					</div>
				</div>
			</div>
		</div>
	</nav>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, socials, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const showMenu = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');

const toggleMenu = () => {
	showMenu.value = !showMenu.value;
};

const hideMenu = () => {
	showMenu.value = false;
};
</script>

<style lang="scss" scoped>
nav {
	width: 100%;
	padding: 15px 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	transition: all 0.3s ease-in-out;

	.row {
		overflow: visible;
	}
}

.logo {
	float: left;
	color: #fff;
	font-size: 16px;

	&:hover {
		color: #fff;
	}

	&.black {
		color: #111;
	}

	strong {
		font-size: 30px;
		letter-spacing: 2px;
	}

	img {
		max-width: 100px;
	}
}

.main-nav {
	list-style: none;
	text-align: left;
	font-weight: 500;
	line-height: 26px;
	width: 55%;
	float: left;
	visibility: visible;

	& > li {
		display: block;
		float: left;
		margin: 10px 0;
		width: 100%;
	}

	a {
		color: var(--body-color);
		padding: 5px 0;
		font-weight: 300;
		font-size: 20px;
		text-decoration: none;
		transition: color 0.3s ease-in-out;

		&.active,
		&:hover {
			color: var(--cta-color);
		}
	}
}

.contact-info {
	float: left;
	width: 45%;
}

.lang-nav {
	list-style: none;
	text-align: left;
	float: left;
	width: 50%;
	line-height: 26px;
	padding-top: 30px;

	a {
		color: #000;

		&.router-link-exact-active,
		&:hover {
			color: var(--cta-color);
		}
	}

	img {
		max-width: 16px;
		float: left;
		margin: 5px 8px 0 0;
	}
}

.nav-container {
	position: fixed;
	top: 0;
	right: -105vw;
	bottom: 0;
	background: #fff;
	min-width: 36%;
	width: 700px;
	max-width: 100%;
	padding: 150px 50px 20px;
	z-index: 100;
	box-shadow: 0 0 20px rgb(0 0 0 / 10%);
	transition: right 0.3s ease-in-out;
}

.active .nav-container {
	right: 0;
	transition: right 0.5s ease-in-out;
}

.nav-overlay {
	display: none;
	position: fixed;
	inset: 0;
	background: rgb(0 0 0 / 30%);
	z-index: 99;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;

	&.active {
		display: block;
		opacity: 1;
	}
}

nav .buttons {
	position: fixed;
	top: 15px;
	right: 20px;
	z-index: 101;

	.button {
		margin-top: 4px;
		margin-left: 6px;
		padding: 15px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			margin-right: 10px;
			width: 1em;
		}
	}

	.menu-button {
		cursor: pointer;
		background: #fff;
		color: #000;
		border: 1px solid var(--body-color);
		padding: 16px 24px;

		&:hover {
			color: var(--cta-color);
			border-color: var(--cta-color);
		}

		&.active {
			color: #000;
			border-color: #000;

			i::before {
				content: '\f00d';
				font-weight: 300;
				color: #000;
			}
		}
	}
}

@media (max-height: 740px) {
	.nav-container {
		overflow-y: scroll;
	}

	.lang-nav {
		position: relative;
		bottom: auto;
		left: auto;
		z-index: 1;
	}

	.main-nav {
		z-index: 2;
		position: relative;
	}
}

@media (max-width: 800px) {
	.lang-nav {
		position: relative;
		bottom: auto;
		left: auto;
		right: auto;
		float: left;
		margin-top: 30px;
	}
}

@media (max-width: 600px) {
	.nav-container {
		padding: 100px 25px 25px;
	}

	.main-nav,
	.contact-info,
	.lang-nav {
		width: 100%;
	}

	.contact-info {
		display: none;
	}

	.lang-nav {
		margin-top: 0;
	}

	nav .buttons .button {
		&.reserve-now {
			display: none;
		}
	}
}
</style>
